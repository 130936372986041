import store from "@/store";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { notif } from '@/store/stateless/store';

// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class SplitPaymentService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    SplitPaymentService.vueInstance = app;
  }

  public static getSplitPaymentList(payload, index = 1) {
    payload.index = index;
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_SPLIT_PAYMENT_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });

  }

  public static fetchInvoiceList(payload, index = 1) {
    payload.index = index;
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.FETCH_INVOICE_LIST, payload)
        .then((data) => {
          console.log('1');
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });

  }

  public static updateSplitPaymentStatus(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.UPDATE_SPLIT_PAYMENT_STATUS, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  
}
export default SplitPaymentService;