
  import { computed, defineComponent, getCurrentInstance, ref } from "vue";
  import { useStore } from "vuex";
  import { Mutations } from "@/store/enums/StoreEnums";
  
  interface SplitPaymentApproveModalBodyParam {
    installment_id: string;
    status: string;
    payment_date: string;
    cib_receipt: string;
    installment_amount: string;
  }

  export default defineComponent({
    props: {
      isError: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
  
    setup(props) {
      const store = useStore();
      let loading = ref(false);
      const inst  = getCurrentInstance()
  
      const splitPaymentApprovalBodyParam = ref<SplitPaymentApproveModalBodyParam>({
                                      installment_id: '',
                                      status: '',
                                      payment_date: '',
                                      cib_receipt: store.getters.getSplitPaymentModalValue.cib_receipt_no,
                                      installment_amount: store.getters.getSplitPaymentModalValue.installment_amount,
                                });
  

      const splitPaymentOpenModal = computed(() => {
          // splitPaymentApprovalBodyParam.value.installment_id ='';
          // splitPaymentApprovalBodyParam.value.status ='';
          // splitPaymentApprovalBodyParam.value.payment_date ='';
          // splitPaymentApprovalBodyParam.value.cib_receipt ='';
           return store.getters.getSplitPaymentModal
       })

       const splitPaymentOpenModalValue = computed(() => {
           return store.getters.getSplitPaymentModalValue
       })

       const approveSplitPaymentRequest = computed(() => {
        const approvalBodyParam = { ...splitPaymentApprovalBodyParam.value }; // Make a copy of the current value
    
          // Set values based on splitPaymentOpenModalValue
          approvalBodyParam.installment_id = splitPaymentOpenModalValue.value.installment_id;
          approvalBodyParam.status = splitPaymentOpenModalValue.value.status;

           return approvalBodyParam;
       })

       const splitPaymentOpenModalLoading = computed(() => {
           return store.getters.getSplitPaymentModalLoading
       })

      const getSplitPaymentUpdateErrors = computed(() => {
          const err = store.getters.getSplitPaymentUpdateErrors;
          return (err && err.cib_receipt_no) ? err.cib_receipt_no : false;
      });
  
      const toFirst = () => {
        setTimeout(() => {
            const splitPaymentModalCancelRef:any = inst?.refs.splitPaymentModalCancelRef
            splitPaymentApprovalBodyParam.value.payment_date ='';
            splitPaymentApprovalBodyParam.value.cib_receipt =store.getters.getSplitPaymentModalValue.cib_receipt_no;
            splitPaymentApprovalBodyParam.value.installment_amount =store.getters.getSplitPaymentModalValue.installment_amount;

            splitPaymentModalCancelRef.focus()
        }, 500);
      }

      const splitPaymentApprovalRules = ref({
                          payment_date: {
                            show: false,
                            message: "Please select payment received date.",
                          },
                          cib_receipt: {
                            show: false,
                            message: "Please enter Internal Receipt No.",
                          },
                      });

      const validateModalData = ()=> {
          let valid = true;
          if (!splitPaymentApprovalBodyParam.value.payment_date) {
            splitPaymentApprovalRules.value.payment_date.show = true;
            valid = false;
          } else {
            splitPaymentApprovalRules.value.payment_date.show = false;
          }

          if (!splitPaymentApprovalBodyParam.value.cib_receipt) {
            splitPaymentApprovalRules.value.cib_receipt.show = true;
            valid = false;
          } else {
            splitPaymentApprovalRules.value.cib_receipt.show = false;
          }

          if(valid){
            inst?.emit('submitSplitPaymentModal', approveSplitPaymentRequest.value)
          }

      }

      const validateReceiptNo = (event) => {
        if (event.target.value.length > 50) {
          event.target.value = event.target.value.slice(0, 50);
        }
        splitPaymentApprovalBodyParam.value.cib_receipt = event.target.value;
        if (splitPaymentApprovalBodyParam.value.cib_receipt) {
          splitPaymentApprovalRules.value.cib_receipt.show = false;
        }
        store.commit(Mutations.SET_SPLIT_PAYMENT_UPDATE_ERROR, false);
      }      

      const clearDateValidation = () => {
        splitPaymentApprovalRules.value.payment_date.show = false;
      }

      const cancelModal = () => {
      // Reset fields and hide error messages
      splitPaymentApprovalBodyParam.value.payment_date = '';
      splitPaymentApprovalBodyParam.value.cib_receipt = '';
      splitPaymentApprovalBodyParam.value.installment_amount = '';
      splitPaymentApprovalRules.value.payment_date.show = false;
      splitPaymentApprovalRules.value.cib_receipt.show = false;
      inst?.emit('cancelModal', splitPaymentOpenModalValue.value)
    }

      return {
        toFirst,
        loading,
        splitPaymentOpenModal,
        splitPaymentOpenModalValue,
        splitPaymentOpenModalLoading,
        splitPaymentApprovalRules,
        splitPaymentApprovalBodyParam,
        approveSplitPaymentRequest,
        validateModalData,
        validateReceiptNo,
        clearDateValidation,
        cancelModal,
        getSplitPaymentUpdateErrors
      };
    },
  });
  